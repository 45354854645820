<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="getUsuarios.length"
          />
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <b-col
          md="4"
          sm="4"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="Usuario"
            modulo="usuarios"
            @processAdd="addUsuario"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="getUsuarios"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Column: Producto -->
            <template #cell(nombre)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.imagen"
                    :text="avatarText(data.item.nombre)"
                  />
                  <!-- :variant="`light-${resolveUserRoleVariant(data.item.role)}`" -->
                  <!-- :to="{ name: 'apps-users-view', params: { id: data.item.id } }" -->
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                >
                  <!-- :to="{ name: 'apps-users-view', params: { id: data.item.id } }" -->
                  {{ data.item.nombre }}
                </b-link>
                <small class="text-muted">{{ data.item.telefonoInterno }}</small>
              </b-media>
            </template>

            <!-- COLUMNA ESTADO -->
            <template #cell(estado)="data">
              <colEstado
                :data="data"
                modulo="usuarios"
                @processUpdateEstado="updateEstado"
              />
            </template>

            <!-- Column: Action -->
            <template #cell(acciones)="data">
              <colAccionesBtnes
                modulo="usuarios"
                :data="data"
                :estado="data.item.destroy.estado"
                :cantidadSalidas="data.item.destroy.cantidadSalidas"
                :cantidadCompras="data.item.destroy.cantidadCompras"
                @processGoToUpdate="goToUpdateUsuario"
                @processRemove="removeUsuario(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BMedia, BLink, BAvatar, BOverlay, BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { avatarText } from '@core/utils/filter'

const colAccionesBtnes = () => import('@/layouts/components/Recycled/List/colAccionesBtnesUsuarios.vue')
const btnCrear = () => import('@/layouts/components/Recycled/List/btnCrear.vue')
const inputFiltro = () => import('@/layouts/components/Recycled/List/inputFiltro.vue')
const btnMostrar = () => import('@/layouts/components/Recycled/List/btnMostrar.vue')
const colEstado = () => import('@/layouts/components/Recycled/List/colEstado.vue')
const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BMedia,
    BLink,
    BAvatar,
    BOverlay,
    BCard,

    spinner,
    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
  },
  data() {
    return {
      spinner: false,
      cargando: true,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'telefonoInterno',
          label: 'Teléfono interno',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '50px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'telefono',
          label: 'Teléfono',
          sortable: false,
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'correo',
          label: 'Correo',
          sortable: false,
          thStyle: {
            width: '80px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'tipoUsuario',
          label: 'Rol',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '80px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '70px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getUsuarios: 'usuarios/getUsuarios' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getUsuarios(val) {
      this.totalRows = val.length
    },
  },
  mounted() {
    this.fetchUsuarios().then(() => {
      this.cargando = false
    })
  },
  methods: {
    ...mapActions({
      fetchUsuarios: 'usuarios/fetchUsuarios',
      updateUsuarioEstado: 'usuarios/updateUsuarioEstado',
      removeUsuarios: 'usuarios/removeUsuarios',
    }),
    ...mapMutations('usuarios', ['setUsuario']),
    addUsuario() {
      this.$router.replace({
        name: 'usuarios-create',
      })
    },
    updateEstado(usuario) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${usuario.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.updateUsuarioEstado(usuario).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Estado actualizado!',
              text: `Se ha actualizado el estado de "${usuario.nombre}"!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    goToUpdateUsuario(usuario) {
      this.setUsuario(usuario)
      this.$router.push({
        name: 'usuarios-update',
        params: { id: usuario.id },
      })
    },
    removeUsuario(usuario) {
      this.$swal({
        title: 'Eliminar usuario!',
        text: `Estás seguro que deseas eliminar el usuario "${usuario.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeUsuarios(usuario.id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminado con éxito!',
              text: `"${usuario.nombre}" ha sido eliminado!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
